import { FC } from "react";

import { ReactComponent as Tevian } from "./tevian.svg";
import classNames from "classnames";
import styles from "./Logo.module.scss";
import { ReactComponent as InfoIcon } from "./info.svg";
import { useTranslation } from "react-i18next";

export type Props = { handleClick?: () => void; addClasses?: string[] };

const Logo: FC<Props> = ({ handleClick, addClasses }) => {
  const { t } = useTranslation();
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  return (
    <div className={className}>
      <div className={styles["logo-container"]}>
        <a href="https://tevian.ai" target="_blank" rel="noreferrer">
          <Tevian className={styles["logo"]} />
        </a>
        <InfoIcon onClick={handleClick} className={styles["info-icon"]} />
      </div>
      <span className={styles["name"]}>{t("demo.name")}</span>
    </div>
  );
};

export default Logo;
