import { Alert } from "@mui/material";
import useNotification from "./useNotification";
import { useTranslation } from "react-i18next";

type Props = {
  extraClasses?: string;
};

const Notification = ({ extraClasses }: Props) => {
  const { message, severity, insertedValues } = useNotification();
  const { t } = useTranslation();
  if (!message) return null;
  return (
    <div className={extraClasses}>
      <Alert
        severity={severity}
        sx={{
          opacity: message ? 1 : 0,
          color: "primary.dark",
          "& .MuiAlert-icon": {
            color: "primary.dark",
          },
        }}
      >
        {t(message, { ...insertedValues })}
      </Alert>
    </div>
  );
};

export default Notification;
