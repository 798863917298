// v1.0.2
import { FC, HTMLProps, useEffect, useRef } from "react";
import styles from "./Description.module.scss";
import classNames from "classnames";
import gsap from "gsap";
import { useTranslation } from "react-i18next";

export type Props = {
  opened: boolean;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const Description: FC<Props> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { opened, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    { [styles["closed"]]: !opened }
  );

  const closeDescription = () => {
    gsap.to(containerRef.current, {
      height: 0,
    });
  };

  const openDescription = () => {
    gsap.to(containerRef.current, {
      height: "auto",
    });
  };

  useEffect(() => {
    if (opened) {
      openDescription();
    } else {
      closeDescription();
    }
  }, [opened]);

  return (
    <div className={className} {...props} ref={containerRef}>
      <div className={styles["text-container"]}>
        <span>{t("demo.description_1")}</span>
        <br />
        <br />
        <span>{t("demo.description_2")}</span>
      </div>
    </div>
  );
};

export default Description;
