// v1.0.1

import { ChangeEvent, FC, HTMLProps } from "react";
import styles from "./UploadButton.module.scss";
import classNames from "classnames";
import { Button } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";

export type Props = {
  disabled: boolean;
  handleUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const UploadButton: FC<Props> = (props) => {
  const { disabled, handleUpload, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  return (
    <div className={className} {...props}>
      <Button disabled={disabled} endIcon={<FileUploadIcon />}>
        <label htmlFor="upload">Загрузить</label>
      </Button>
      <input
        type="file"
        id="upload"
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleUpload}
      />
    </div>
  );
};

export default UploadButton;
