import { HTMLProps, forwardRef } from "react";
import styles from "./DocImgPreview.module.scss";
import classNames from "classnames";
import Spinner from "../Spinner";

export type Props = {
  loading?: boolean;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

type Ref = HTMLImageElement;

const DocImgPreview = forwardRef<Ref, Props>((props, ref) => {
  const { loading, addClasses } = props;
  const className = classNames(
    [
      styles["container"],
      "doc-preview-img-container",
      addClasses && [...addClasses],
    ],
    {}
  );

  return (
    <div className={className} {...props}>
      <img ref={ref} alt="docPreview" />
      {loading && (
        <div className={styles["loader"]}>
          <Spinner />
        </div>
      )}
    </div>
  );
});

export default DocImgPreview;
