import { createTheme } from "@mui/material/styles";
import { main } from "../utils/constants";

const theme = createTheme({
  palette: {
    primary: {
      main,
      dark: "#263B47",
    },
  },
});

export default theme;
