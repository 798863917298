import { heicToURL } from "../utils/functions";

const useFile = () => {
  async function handleFileSelection_path(
    e: React.ChangeEvent<HTMLInputElement>,
    handleError?: (error: any) => void
  ) {
    if (!e.target.files?.length) return false;
    try {
      const file = e.target.files[0];
      const { name } = file;
      let path = window.URL.createObjectURL(file);
      if (name.includes(".HEIC")) {
        path = await heicToURL(file);
      }
      return { path, name };
    } catch (error) {
      if (handleError) {
        handleError(error);
      } else {
        throw error;
      }
    }
  }

  async function handleFileSelection_file(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    if (!e.target.files?.length) return false;
    try {
      const { path, name } = (await handleFileSelection_path(e)) as {
        path: string;
        name: string;
      };
      const file = await getFileByPath(path)!;
      e.target!.value = "";
      return { file, path, name };
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  const getFileByPath = async (
    path: string,
    handleError?: (error: any) => void
  ) => {
    try {
      const file = await fetch(path).then((r) => r.blob());
      return file;
    } catch (error) {
      if (handleError) {
        handleError(error);
      } else {
        throw error;
      }
    }
  };

  return { handleFileSelection_path, handleFileSelection_file, getFileByPath };
};

export default useFile;
