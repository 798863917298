import axios from "axios";
import { base_url, token } from "../utils/constants";
import { DetectResponse } from "../utils/types";

const useRequest = () => {
  const makeCompareRequest = async (
    image1: Blob,
    image2: Blob,
    bbox1: number[],
    bbox2: number[]
  ) => {
    try {
      const formData = new FormData();
      formData.append("image1", image1);
      formData.append("image2", image2);
      const res = await axios<{ data: { score: number } }>({
        url: `${base_url}/match?face1=${bbox1.join(",")}&face2=${bbox2.join(
          ","
        )}`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      });
      return res.data.data.score;
    } catch (e: any) {
      throw new Error(e.message);
    }
  };

  const makeDetectRequest = async (
    blob: Blob,
    fd_min_size: number,
    person?: boolean
  ) => {
    try {
      let url = `${base_url}/detect?fd_min_size=${fd_min_size}`;
      if (person) {
        url += "&liveness=true";
      }
      const res = await axios<DetectResponse>({
        url,
        method: "POST",
        headers: {
          "Content-Type": "image/jpeg",
          Authorization: `Bearer ${token}`,
        },
        data: blob,
      });
      return res.data;
    } catch (e: any) {
      throw new Error(e.message);
    }
  };

  return { makeCompareRequest, makeDetectRequest };
};

export default useRequest;
