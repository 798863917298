import styles from "./app.module.scss";
import { useAppContext } from "./utils/context/AppProvider";
import Notification from "./components/Notification";
import BottomSection from "./components/BottomSection";
import Navigation from "./components/Navigation";
import InitialScreen from "./Screens/InitialScreen";
import ResultScreen from "./Screens/ResultScreen";
import { useEffect } from "react";

declare global {
  interface Window {
    FACE_FD_ROI_RATIO: number;
    FACE_FD_ROI_PADDING: number;
    FACE_LIVENESS_THRESHOLD: number;
    FACE_FD_MIN_HEIGHT_ROI_FRAC: number;
    FACE_FD_ROI_VISUALIZATION_SHRINK_FRAC: number;
    DOCUMENT_FD_MIN_HEIGHT_FRAC: number;
    SIMILARITY_THRESHOLD: number;
    IMAGE_AREA_THRESHOLD_MP: number;
    API_URL: string;
    API_TOKEN: string;
    SENTRY_DSN: string;
    SENTRY_TRACES_RATE: string;
    SENTRY_ENVIRONMENT: string;
    YANDEX_METRIC_ID: string;
    IMAGE_VERSION: string;
    BUILD_VERSION: string;
    SHOW_VERSION_INFO: string;
  }
}

function App() {
  const { showResults } = useAppContext();
  useEffect(() => {
    (window as any).ym(window.YANDEX_METRIC_ID, "hit", window.location.href);
  }, []);
  return (
    <div className={styles["app-container"]}>
      <div className={styles["max-width"]}>
        <Navigation />
      </div>
      <Notification extraClasses={styles["notification"]} />
      <div className={styles["max-width"]}>
        <div className={styles["screen-container"]}>
          {showResults ? <ResultScreen /> : <InitialScreen />}
        </div>
      </div>
      <BottomSection />
    </div>
  );
}

export default App;
