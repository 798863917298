import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import theme from "./theme";
import { AppProvider } from "./utils/context/AppProvider";
import { ThemeProvider } from "@mui/material";
import "./translations";
import { NotificationProvider } from "./utils/context/NotificationProvider";

const {
  SENTRY_DSN,
  SENTRY_TRACES_RATE,
  SENTRY_ENVIRONMENT,
  IMAGE_VERSION,
  BUILD_VERSION,
} = window;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: +SENTRY_TRACES_RATE,
  environment: SENTRY_ENVIRONMENT,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

console.log(IMAGE_VERSION, BUILD_VERSION);

root.render(
  <NotificationProvider>
    <AppProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AppProvider>
  </NotificationProvider>
);
