import { AlertColor } from "@mui/material";
import React, { useState } from "react";

type Props = {
  children: React.ReactNode;
};

const NotificationContext = React.createContext(
  {} as {
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    severity: AlertColor;
    setSeverity: React.Dispatch<React.SetStateAction<AlertColor>>;
    insertedValues: {};
    setInsertedValues: React.Dispatch<React.SetStateAction<{}>>;
  }
);

function NotificationProvider({ children }: Props) {
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<AlertColor>("info");
  const [insertedValues, setInsertedValues] = useState({});
  return (
    <NotificationContext.Provider
      value={{
        message,
        setMessage,
        severity,
        setSeverity,
        insertedValues,
        setInsertedValues,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

function useNotificationProvider() {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      "useNotificationProvider must be used within a NotificationProvider"
    );
  }
  return context;
}

export { NotificationProvider, useNotificationProvider };
