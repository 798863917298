import React, { createContext, useState } from "react";
import { DetectedFace } from "../types";

type AppContextType = {
  firstFaceResult: DetectedFace | undefined;
  setFirstFaceResult: React.Dispatch<
    React.SetStateAction<DetectedFace | undefined>
  >;
  firstFaceImgUrl: string;
  setFirstFaceImgUrl: React.Dispatch<React.SetStateAction<string>>;
  secondFaceResult: DetectedFace | undefined;
  setSecondFaceResult: React.Dispatch<
    React.SetStateAction<DetectedFace | undefined>
  >;
  secondFaceImgUrl: string;
  setSecondFaceImgUrl: React.Dispatch<React.SetStateAction<string>>;
  showResults: boolean;
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>;
  photosCompareScore: number;
  setPhotosCompareScore: React.Dispatch<React.SetStateAction<number>>;
};

type ProviderType = {
  children: React.ReactNode;
};

const AppContext = createContext({} as AppContextType);

const AppProvider = ({ children }: ProviderType) => {
  const [firstFaceResult, setFirstFaceResult] = useState<DetectedFace>();
  const [firstFaceImgUrl, setFirstFaceImgUrl] = useState("");
  const [secondFaceResult, setSecondFaceResult] = useState<DetectedFace>();
  const [secondFaceImgUrl, setSecondFaceImgUrl] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [photosCompareScore, setPhotosCompareScore] = useState(0);

  return (
    <AppContext.Provider
      value={{
        firstFaceResult,
        setFirstFaceResult,
        firstFaceImgUrl,
        setFirstFaceImgUrl,
        secondFaceResult,
        setSecondFaceResult,
        secondFaceImgUrl,
        setSecondFaceImgUrl,
        showResults,
        setShowResults,
        photosCompareScore,
        setPhotosCompareScore,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("App context is undefined");
  }
  return context;
};

export { AppProvider, useAppContext };
