import { useNotificationProvider } from "../../utils/context/NotificationProvider";

const useNotification = () => {
  const {
    message,
    setMessage,
    severity,
    setSeverity,
    setInsertedValues,
    insertedValues,
  } = useNotificationProvider();

  const notifyInfo = (message: string) => {
    setSeverity("info");
    setMessage(message);
  };

  const notifyError = (message: string, insertedValues?: {}) => {
    setSeverity("error");
    setMessage(message);
    insertedValues && setInsertedValues(insertedValues);
  };

  const notifySuccess = (message: string, insertedValues?: {}) => {
    setSeverity("success");
    setMessage(message);
    insertedValues && setInsertedValues(insertedValues);
  };

  const clearNotification = () => {
    setSeverity("info");
    setMessage("");
    setInsertedValues({});
  };

  return {
    notifyInfo,
    notifyError,
    clearNotification,
    notifySuccess,
    message,
    severity,
    insertedValues,
  };
};

export default useNotification;
