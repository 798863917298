// v1.0.1
import { useCallback, useEffect, useRef, useState } from "react";

const useUserVideo = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState({ w: 0, h: 0 });
  const [currentStream, setCurrentStream] = useState<MediaStream>();
  const [cameraList, setCameraList] = useState<MediaDeviceInfo[]>([]);

  const stopStream = useCallback(() => {
    if (currentStream) {
      currentStream.getTracks().forEach((t) => {
        t.stop();
      });
    }
  }, [currentStream]);

  const startCam = async (constraints: {}) => {
    setLoading(true);
    stopStream();
    try {
      const video = videoRef.current!;
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameraList = devices.filter((d) => d.kind === "videoinput");
      video.srcObject = stream;
      video.addEventListener("loadeddata", () => {
        setSize({ w: video.videoWidth, h: video.videoHeight });
      });
      video.play();
      setCurrentStream(stream);
      setCameraList(cameraList);
    } catch (e: any) {
      console.log(e);
      throw new Error("startCamError");
    } finally {
      // on iOS cam needs time to fully load
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const switchCam = async (constraints: {}) => {
    setLoading(true);
    stopStream();
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setCurrentStream(stream);
      const video = videoRef.current!;
      video.srcObject = stream;
      video.play();
    } catch (e) {
      console.log(e);
      throw new Error("switchCamError");
    } finally {
      // on iOS cam needs time to fully load
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    return () => {
      stopStream();
    };
  }, [stopStream]);

  return {
    loading,
    videoRef,
    size,
    cameraList,
    switchCam,
    startCam,
    stopStream,
    currentStream,
  };
};

export default useUserVideo;
