// v1.0.1
import { HTMLProps, forwardRef } from "react";
import styles from "./UserVideo.module.scss";
import classNames from "classnames";
import Spinner from "../Spinner";

export type Props = {
  loading?: boolean;
  mirrored?: boolean;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

type Ref = HTMLVideoElement;

const UserVideo = forwardRef<Ref, Props>((props, ref) => {
  const { loading, mirrored, addClasses, children } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  const videoClasses = classNames([styles["video"]], {
    [styles["video__mirrored"]]: mirrored,
  });

  return (
    <div className={className} {...(props as any)}>
      {loading && (
        <div className={styles["loader"]}>
          <Spinner />
        </div>
      )}
      <video className={videoClasses} ref={ref} playsInline muted></video>
      {children}
    </div>
  );
});

export default UserVideo;
