import { FC } from "react";
import classNames from "classnames";

import styles from "./Spinner.module.scss";

export type Props = { addClasses?: string[] };

const Spinner: FC<Props> = ({ addClasses }) => {
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  return <div className={className}></div>;
};

export default Spinner;
