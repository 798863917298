export const constraints = {
  audio: false,
  video: {
    facingMode: "user",
    width: 1920,
    height: 1080,
  },
};

export const base_url = window.API_URL;
export const token = window.API_TOKEN;
export const face_fd_roi_ratio = window.FACE_FD_ROI_RATIO;
export const face_fd_roi_padding = window.FACE_FD_ROI_PADDING;
export const face_fd_min_h_roi_frac = window.FACE_FD_MIN_HEIGHT_ROI_FRAC;
export const doc_fd_min_h_frac = window.DOCUMENT_FD_MIN_HEIGHT_FRAC;
export const face_fd_roi_visualization_shrink_frac =
  window.FACE_FD_ROI_VISUALIZATION_SHRINK_FRAC;
export const liveness_threshold = window.FACE_LIVENESS_THRESHOLD;
export const similarity_threshold = window.SIMILARITY_THRESHOLD;
export const image_area_threshold_mp = window.IMAGE_AREA_THRESHOLD_MP;

// Colors
export const main = "#036AB5";
export const secondary = "#D8E3EC";
export const text = "#263B47";
export const roiColor = "#1565c0";
